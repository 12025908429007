import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

import Layout from '../layouts/MainLayout/MainLayout';
import DataTable from 'components/common/DataTable';

import { restService } from 'utilities';
import Select from 'components/UI/Select';
import Loader from 'components/UI/Loader';
import {
  LIQUIDITY_CLOSE_FACTOR,
  LIQUIDITY_INCENTIVE,
  ERC20_TOKEN_ABI,
  CONTRACT_ABEP_ADDRESS,
  CONTRACT_AETH_ABI,
  CONTRACT_ABEP_ABI
} from '../utilities/constants';
import BigNumber from 'bignumber.js';
import { useActiveWeb3React, useWeb3 } from '../hooks';
import {
  getContract,
  methods,
} from '../utilities/ContractService';
import toast from 'components/UI/Toast';
import ConnectWalletButton from '../components/common/ConnectWalletButton';
import { getEtherscanLink, getNativeToken, shortenAddress } from 'utils';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import Loading from 'components/UI/Loading';

import noData from 'assets/img/common/no_history.svg';
import { useWindowSize } from 'hooks/useWindowSize';

const ContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 992px) {
    gap: 32px;
  }
  .sub-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    @media (max-width: 992px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .title {
      padding: 0px 0px 16px 0px;
      gap: 10px;
      border-bottom: 1px solid #FFFFFF33;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      @media (max-width: 992px) {
        font-size: 18px;
        line-height: 28px;
      }
    }
    .item-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        .item-value {
          text-align: right;
          color: #9CA3AF;
        }
        @media (max-width: 992px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .divider {
      border-bottom: 1px solid #FFFFFF33;
    }
    .last-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
      }
      .input-container {
        display: flex;
        padding: 10px 16px 10px 16px;
        border-radius: 4px;
        border: 1px solid #FFFFFF33;
        background: #00000033;
        gap: 10px;
        input {
          flex: 1;
          border: none;
          background: transparent;
          &:focus {
            outline: none;
          }
          ::placeholder {
            padding: 0 !important;
            color: #9CA3AF;
          }
        }
      }
      button {
        padding: 10px 16px 10px 16px;
        border-radius: 4px;
        border: 1px solid #0EA5E9
        gap: 10px;
        background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
        &:hover {
          box-shadow: 0px 4px 24px 4px #2566EC33;
        }
      }
    }
  }
  .total-borrow {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
  }
  .health {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;    
  }
  .address {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: right;
  }
`

const Card = styled.div`
  padding: 24px;
  border-radius: 12px;
  gap: 20px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid #FFFFFF1A;
  display: flex;
  flex-direction: column;
`;

const NoAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
  background: #FFFFFF0D;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 6px 6px;
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: rgb(157, 159, 167);
    text-align: center;
  }
`;

const Liquidate = () => {
  const assetList = useSelector((state) => state.account.setting.assetList);

  const web3 = useWeb3();
  const { requiredChainId, account } = useActiveWeb3React();
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedBorrowers, setSelectedBorrowers] = useState(null)
  const [selectedBorrowInfo, setSelectedBorrowInfo] = useState(null)
  const [selectedSupplyInfo, setSelectedSupplyInfo] = useState(null)
  const [selectedBorrowerValue, setSelectedBorrowerValue] = useState('')
  const [selectedSupplyValue, setSelectedSupplyValue] = useState('')

  const [tokenAllowance, setTokenAllowance] = useState({})
  const [buttonText, setButtonText] = useState(t('Approve'))
  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    fetchData()
  }, [requiredChainId]);

  useEffect(() => {
    getApprove();
  }, [assetList])

  useEffect(() => {
    if (selectedBorrowInfo) {
      const repayAssetInfo = selectedBorrowers?.borrowerInfos.find((item) => item.type === 'borrow' && item.id == selectedBorrowInfo?.value)
      const amount = new BigNumber(selectedBorrowerValue).times(new BigNumber(10).pow(repayAssetInfo?.decimals))
      const allowance = tokenAllowance[selectedBorrowInfo.name] || new BigNumber(0);
      if (allowance.gte(amount)) {
        setButtonText(t('Liquidate'))
      } else {
        setButtonText(t('Approve'))
      }
    }
  }, [tokenAllowance, selectedBorrowInfo, selectedBorrowerValue, selectedBorrowers])

  const fetchData = async () => {
    setTableLoading(true)
    const apiRequest = await restService({
      chainId: requiredChainId,
      api: `${'/v1/borrowers'}`,
      method: 'GET',
    });
    setTableLoading(false)
    if (apiRequest?.data?.data?.result) {
      setData(apiRequest.data.data.result)
    }
  }

  const findTokenIcon = (symbol) => {
    const token = assetList?.find((token) => {
      return token.name === symbol
    })
    return token?.img || ''
  }

  const borrowSelectOption = useMemo(() => {
    if (selectedBorrowers?.borrowerInfos) {
      let arr = selectedBorrowers.borrowerInfos.map((item) => {
        if (item.type == 'borrow') {
          return {
            name: item.symbol,
            logo: findTokenIcon(item.symbol),
            value: item.id
          }
        }
        return null
      })
      arr = arr.filter(Boolean);
      setSelectedBorrowInfo(arr[0])
      return arr
    }
    return [{ name: t('Select_to_Borrow_To_Close'), value: '' }]
  }, [selectedBorrowers])

  const supplySelectOption = useMemo(() => {
    if (selectedBorrowers?.borrowerInfos) {
      let arr = selectedBorrowers.borrowerInfos.map((item) => {
        if (item.type == 'supply') {
          return {
            name: item.symbol,
            logo: findTokenIcon(item.symbol),
            value: item.id
          }
        }
        return null
      })
      arr = arr.filter(Boolean);
      setSelectedSupplyInfo(arr[0])
      return arr
    }
    return [{ name: t('Select_Desired_Collateral'), value: '' }]
  }, [selectedBorrowers])

  useEffect(() => {
    if (selectedBorrowInfo && selectedSupplyInfo) {
      selectAssets(selectedBorrowInfo, selectedSupplyInfo)
    }
  }, [selectedBorrowInfo, selectedSupplyInfo])

  const findSelectedBorrowInfo = useMemo(() => {
    return selectedBorrowers?.borrowerInfos.find((item) => item.type === 'borrow' && item.id == selectedBorrowInfo?.value)
  }, [selectedBorrowInfo])
  const findSelectedSupplyInfo = useMemo(() => {
    return selectedBorrowers?.borrowerInfos.find((item) => item.type === 'supply' && item.id == selectedSupplyInfo?.value)
  }, [selectedSupplyInfo])

  const getErrorMessage = (msg) => {
    if (!msg) return msg;
    if (msg.includes('MetaMask Tx Signature: User denied transaction signature')) {
      return t("User_denied")
    }
    return msg;
  }

  const handleLiquidateClick = async () => {
    if (!account) {
      toast.error({
        title: t(`Please_connect_to_wallet`)
      })
      return
    }
    if (!selectedBorrowerValue) {
      toast.error({
        title: t(`Invalid_Value`)
      })
      return
    }
    if (!findSelectedBorrowInfo || !findSelectedBorrowInfo) {
      toast.error({
        title: t(`Invalid_Value`)
      })
      return
    }
    const amount = new BigNumber(selectedBorrowerValue).times(new BigNumber(10).pow(findSelectedBorrowInfo?.decimals)).dp(0, 1)
    const allowance = tokenAllowance[findSelectedBorrowInfo?.symbol] || new BigNumber(0)

    try {
      if (amount.isGreaterThan(allowance)) {
        setButtonLoading(true);
        const appContract = getContract(
          web3,
          requiredChainId,
          ERC20_TOKEN_ABI,
          findSelectedBorrowInfo.address,
        );
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          appContract.methods.approve,
          [
            CONTRACT_ABEP_ADDRESS[requiredChainId][findSelectedBorrowInfo.symbol.toLowerCase()].address,
            amount.toString(10)
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setButtonLoading(false);
          return
        }
        await methods.send(
          appContract.methods.approve,
          [
            CONTRACT_ABEP_ADDRESS[requiredChainId][findSelectedBorrowInfo.symbol.toLowerCase()].address,
            amount.toString(10)
          ],
          account,
        )
          .then(() => {
            setTokenAllowance({
              ...tokenAllowance,
              [findSelectedBorrowInfo.symbol]: amount
            })
            NotificationManager.success(t('Approve_successfully'));
            setButtonLoading(false);
          })
          .catch(() => {
            NotificationManager.error(t('Tx_rejected'));
            setButtonLoading(false);
          })

        return
      }

      const balance = new BigNumber(findWalletBalance()).times(new BigNumber(10).pow(findSelectedBorrowInfo.decimals))
      if (balance.isLessThan(amount)) {
        toast.error({
          title: t(`Insufficient_Balance`)
        });
        return
      }

      setButtonLoading(true)
      if (findSelectedBorrowInfo.symbol === 'CORE' || findSelectedBorrowInfo.symbol === 'ETH') {
        const liquidityContract = getContract(
          web3,
          requiredChainId,
          CONTRACT_AETH_ABI,
          CONTRACT_ABEP_ADDRESS[requiredChainId][findSelectedBorrowInfo.symbol.toLowerCase()].address
        );
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          liquidityContract.methods.liquidateBorrow,
          [
            findSelectedBorrowInfo.borrower,
            CONTRACT_ABEP_ADDRESS[requiredChainId][findSelectedSupplyInfo.symbol.toLowerCase()].address,
          ],
          account,
          amount.toString(10)
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setButtonLoading(false);
          return
        }
        methods.sendWithValue(
          liquidityContract.methods.liquidateBorrow,
          [
            findSelectedBorrowInfo.borrower,
            CONTRACT_ABEP_ADDRESS[requiredChainId][findSelectedSupplyInfo.symbol.toLowerCase()].address,
          ],
          account,
          amount.toString(10)
        )
          .then(() => {
            setButtonLoading(false);
            NotificationManager.success(t('Liquidate_successfully'));
          })
          .catch(() => {
            setButtonLoading(false);
            NotificationManager.error(t('Tx_rejected'));
          });
      } else {
        const liquidityContract = getContract(
          web3,
          requiredChainId,
          CONTRACT_ABEP_ABI,
          CONTRACT_ABEP_ADDRESS[requiredChainId][findSelectedBorrowInfo.symbol.toLowerCase()].address,
        );
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          liquidityContract.methods.liquidateBorrow,
          [
            findSelectedBorrowInfo.borrower,
            amount.toString(10),
            CONTRACT_ABEP_ADDRESS[requiredChainId][findSelectedSupplyInfo.symbol.toLowerCase()].address
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setButtonLoading(false);
          return
        }
        methods.send(
          liquidityContract.methods.liquidateBorrow,
          [
            findSelectedBorrowInfo.borrower,
            amount.toString(10),
            CONTRACT_ABEP_ADDRESS[requiredChainId][findSelectedSupplyInfo.symbol.toLowerCase()].address
          ],
          account,
        )
          .then(() => {
            setButtonLoading(false);
            NotificationManager.success(t('Liquidate_successfully'));
            setButtonLoading(false)
          })
          .catch(() => {
            setButtonLoading(false);
            NotificationManager.error(t('Tx_rejected'));
            setButtonLoading(false)
          });
      }
    } catch (err) {
      console.log(err)
      setButtonLoading(false)
      toast.error({
        title: getErrorMessage(err?.message) || t('Error_message')
      });
    }
  }

  const handleBorrowAmountChange = (val) => {
    setSelectedBorrowerValue(val)

    let repayMax = new BigNumber(val)
    let seizeMax = new BigNumber(findSelectedSupplyInfo.amount).div(new BigNumber(10).pow(findSelectedSupplyInfo.decimals))
    repayMax = repayMax.times(findSelectedBorrowInfo.price)
    seizeMax = seizeMax.div(LIQUIDITY_INCENTIVE).times(findSelectedSupplyInfo.price)

    if (repayMax.isLessThan(seizeMax)) {
      const seizeAmount = repayMax.times(LIQUIDITY_INCENTIVE).div(findSelectedSupplyInfo.price)
      setSelectedSupplyValue(
        seizeAmount.toString(10)
      )
    }
  }

  const findWalletBalance = () => {
    const find = assetList.find((item) => {
      if (item.symbol === findSelectedBorrowInfo?.symbol) {
        return true
      }
      return false
    })
    if (find) {
      return find.walletBalance == '0' ? '0' : find.walletBalance.toString(10)
    }
    return '0'
  }

  const selectAssets = (repayAsset = null, seizeAsset = null) => {
    if (!repayAsset) {
      repayAsset = selectedBorrowInfo
    } else {
      if (repayAsset.value !== '') {
        setSelectedBorrowInfo(repayAsset)
      }
    }
    if (!seizeAsset) {
      seizeAsset = selectedSupplyInfo
    } else {
      if (seizeAsset.value !== '') {
        setSelectedSupplyInfo(seizeAsset)
      }
    }

    if (!repayAsset || !seizeAsset) return;

    const repayAssetInfo = selectedBorrowers?.borrowerInfos.find((item) => item.type === 'borrow' && item.id == repayAsset?.value)
    const seizeAssetInfo = selectedBorrowers?.borrowerInfos.find((item) => item.type === 'supply' && item.id == seizeAsset?.value)

    let repayMax = new BigNumber(repayAssetInfo?.amount).div(new BigNumber(10).pow(repayAssetInfo?.decimals))
    let seizeMax = new BigNumber(seizeAssetInfo?.amount).div(new BigNumber(10).pow(seizeAssetInfo?.decimals))
    repayMax = repayMax.times(LIQUIDITY_CLOSE_FACTOR).times(repayAssetInfo?.price)
    seizeMax = seizeMax.div(LIQUIDITY_INCENTIVE).times(seizeAssetInfo?.price)

    if (repayMax.isLessThan(seizeMax)) {
      const seizeAmount = repayMax.times(LIQUIDITY_INCENTIVE).div(seizeAssetInfo?.price)
      setSelectedBorrowerValue(
        repayMax.div(repayAssetInfo.price).dp(findSelectedBorrowInfo.decimals, 1).toString(10)
      )
      setSelectedSupplyValue(
        seizeAmount.toString(10)
      )
    } else {
      const repayAmount = seizeMax.div(LIQUIDITY_INCENTIVE).div(repayAssetInfo?.price)
      setSelectedBorrowerValue(
        repayAmount.dp(findSelectedBorrowInfo.decimals, 1).toString(10)
      )
      setSelectedSupplyValue(
        seizeMax.div(seizeAssetInfo?.price).toString(10)
      )
    }
  }

  const getApprove = async () => {
    if (account) {
      const borrowInfos = assetList.filter(item => item.tokenAddress)
      const tokenContractCallContext = borrowInfos.map(item => {
        return {
          reference: item.symbol,
          contractAddress: item.tokenAddress,
          abi: ERC20_TOKEN_ABI,
          calls: [
            {
              reference: 'allowance',
              methodName: 'allowance',
              methodParameters: [account, item.atokenAddress]
            },
          ],
          context: {}
        }
      });

      let allowanceData = {
        ETH: new BigNumber(2).pow(256).minus(1),
        CORE: new BigNumber(2).pow(256).minus(1),
      }
      const aBepContractResults = await methods.ethMulticall(web3, tokenContractCallContext, requiredChainId);
      for (const [itemId, value] of Object.entries(aBepContractResults?.results)) {
        allowanceData[itemId] = new BigNumber(value.callsReturnContext[0].returnValues[0].hex)
      }

      setTokenAllowance(allowanceData)
    }
  }

  const getHealthColor = (value) => {
    if (!value) return '#9CA3AF';
    if (value >= 1) return '#9CA3AF';
    if (value < 1) return '#F87171';
    return '#9CA3AF';
  }

  const columns = useMemo(() => {
    return [{
      Header: t("Total_Borrowed"),
      accessor: "totalBorrowBalanceUSD",
      sortable: width > 992,
      Cell: ({ value }) => {
        return (
          <div className="total-borrow" >
            ${new BigNumber(value).toFormat(2)}
          </div>
        )
      }
    },
    {
      Header: t("Health"),
      accessor: "health",
      sortable: width > 992,
      Cell: ({ value }) => {
        return (
          <div className="health" style={{ color: getHealthColor(value) }}>{value}</div>
        )
      }
    },
    {
      Header: t("Address"),
      accessor: 'address',
      sortable: width > 992,
      Cell: ({ value }) => {
        return (
          <div className="address">
            <a href={getEtherscanLink(requiredChainId, value)} target="_blank" rel="noreferrer">
              {width > 992 ? value : shortenAddress(value, 3)}
            </a>
          </div>
        )
      }
    }]
  }, [t, width]);

  return (
    <Layout>
      <ContainerStyles>
        <div className="sub-container">
          <Card>
            <h1 className="title">{t("Repay_Borrow")}</h1>
            <Select
              options={borrowSelectOption}
              selectedProp={selectedBorrowInfo || { name: t('Select_to_Borrow_To_Close'), value: '' }}
              onChange={(item) => selectAssets(item)}
            />
            <div className="item-container">
              <div className="item">
                <p>{t("Price")}</p>
                <p className="item-value">
                  {findSelectedBorrowInfo ? new BigNumber(findSelectedBorrowInfo.price).dp(5, 1).toString(10) : 0}
                </p>
              </div>
              <div className="item">
                <p>{t("User_Borrowed")}</p>
                <p className="item-value">
                  {
                    findSelectedBorrowInfo ?
                      new BigNumber(findSelectedBorrowInfo.amount)
                        .div(new BigNumber(10).pow(findSelectedBorrowInfo.decimals))
                        .toString(10)
                      :
                      0
                  }
                </p>
              </div>
              <div className="item">
                <p>{t("Wallet_Balance")}</p>
                <p className="item-value">{findWalletBalance()}</p>
              </div>
            </div>
            <div className='divider' />
            <div className="last-container">
              <p>{t("You_Will_Pay")}</p>
              <div className="input-container">
                <input
                  type="number"
                  placeholder={t("Amount_to_Close")}
                  value={selectedBorrowerValue}
                  onChange={(e) => { handleBorrowAmountChange(e.target.value) }}
                />
              </div>
            </div>
          </Card>
          <Card>
            <div className="title">{t("Seize_Collateral")}</div>
            <Select
              options={supplySelectOption}
              selectedProp={selectedSupplyInfo || { name: t('Select_Desired_Collateral'), value: '' }}
              onChange={(item) => selectAssets(null, item)}
            />
            <div className="item-container">
              <div className="item">
                <p>{t("Price")}</p>
                <p className="item-value">
                  {findSelectedSupplyInfo ? new BigNumber(findSelectedSupplyInfo.price).dp(5, 1).toString(10) : 0}
                </p>
              </div>
              <div className="item">
                <p>{t("User_Supplied")}</p>
                <p className="item-value">
                  {
                    findSelectedSupplyInfo ?
                      new BigNumber(findSelectedSupplyInfo.amount)
                        .div(new BigNumber(10).pow(findSelectedSupplyInfo.decimals))
                        .toString(10)
                      :
                      0
                  }
                </p>
              </div>
              <div className="item">
                <p>{t("Liquidation_Incentive")}</p>
                <p className="item-value">{new BigNumber(LIQUIDITY_INCENTIVE).toFixed(2)}</p>
              </div>
            </div>
            <div className='divider' />
            <div className="last-container">
              <div className='flex justify-between'>
                <p>{t("You_Will_Receive")}</p>
                <p style={{ color: '#9CA3AF' }}>{
                  new BigNumber(selectedSupplyValue || 0)
                    .dp(2, 1)
                    .toString(10)
                }</p>
              </div>
              {
                account ?
                  (
                    <button
                      onClick={handleLiquidateClick}
                      disabled={buttonLoading || !selectedBorrowers || selectedBorrowers?.health > 1}
                    >
                      {buttonLoading && (
                        <Loader size="20px" className="mr-2.5" stroke="#ffffff" />
                      )}
                      {buttonText}
                    </button>
                  )
                  :
                  (<ConnectWalletButton />)
              }
            </div>
          </Card>
        </div>
        <div>
          <DataTable
            columns={columns}
            data={data}
            onRowClick={(row) => { setSelectedBorrowers(row) }}
            selected={selectedBorrowers}
          />
          {
            tableLoading ? (
              <NoAccountContainer>
                <Loading size={'36px'} margin={'0'} />
              </NoAccountContainer>
            ) : (!data || data.length === 0) ? (
              <NoAccountContainer>
                <img src={noData} alt='' />
              </NoAccountContainer>
            ) :
              null
          }
        </div>
      </ContainerStyles>
    </Layout>
  );
}

export default Liquidate;
