import React from 'react';
import styled from 'styled-components';
import { useExpanded, useSortBy, useTable } from 'react-table';

import sortUp from '../../assets/img/common/arrow_up.svg';
import sortDown from '../../assets/img/common/arrow_down.svg';

const Styles = styled.div`
  width: 100%;
  overflow: auto;
  border-radius: 4px;
  table {
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    color: #fff;
    thead {
      border: 1px solid rgba(31, 41, 55, 1);
      border-radius: 4px 4px 0px 0px;
      background-color: rgba(255, 255, 255, 0.1);
      color: rgba(156, 163, 175, 1);
      tr {
        white-space:nowrap;
        th {
          padding: 12px 24px;
          text-align: left;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          @media (max-width: 992px) {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
          }
          :last-child {
            text-align: right;
            padding: 12px 24px 12px 0px;
          }
          .header-container {
            display: flex;
            align-items: center;
            gap: 12px;
          }
        }
      }
    }
    tbody {
      border-radius: 0px 0px 4px 4px;
      tr {
        border: 1px solid rgba(31, 41, 55, 1);
        border-radius: 0px;
        td {
          padding: 12px 20px;
          :last-child {
            padding: 12px 20px 12px 0px;
          }
        }
        :last-child {
          border-radius: 0px 0px 4px 4px;
        }
      }
      .active-row {
        background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      }
    }
  }
`;

function Table({ columns, data, onRowClick, selected }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data,
    autoResetSortBy: false,
  },
    useSortBy,
    useExpanded,
  );
  return (
    <table {...getTableProps()}>
      <thead>
        {
          [headerGroups[0]].map((headerGroup, index) => {
            return (
              <tr key={`header-group-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map((column, i) => {
                    if (column.sortable) {
                      return (
                        <th key={`header-group-${index}-${i}`}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          <div className='header-container'>
                            {column.render('Header')}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img src={sortDown} alt="sort down" />
                              ) : (
                                <img src={sortUp} alt="sort up" />
                              )
                            ) : (
                              <div className="space-y-0.5">
                                <img src={sortUp} alt="sort up" />
                                <img src={sortDown} alt="sort down" />
                              </div>
                            )}
                          </div>
                        </th>
                      )
                    }
                    return (
                      <th key={`header-group-${index}-${i}`}
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    )
                  })
                }
              </tr>
            )
          })
        }
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={`table-body-${i}`} {...row.getRowProps()}
              className={selected && selected.id === row.original.id ? 'active-row' : ''}
              onClick={() => {
                if (onRowClick) {
                  onRowClick(row.original);
                }
              }}
            >
              {row.cells.map((cell) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <td {...cell.getCellProps()}>
                    <div className='td-div'>{cell.render('Cell')}</div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function App({ columns, data, onRowClick, selected }) {
  return (
    <Styles>
      <Table columns={columns} data={data} onRowClick={onRowClick} selected={selected} />
    </Styles>
  );
}

export default App;
